export function isLoggedIn(): boolean {
  const authToken = localStorage.getItem("AuthToken");
  return !!authToken;
}

export function logout(): void {
  localStorage.removeItem("AuthToken");
  localStorage.removeItem("UserId");
  window.location.href = "/";
}

export function getJwtToken(): string {
  return localStorage.getItem("AuthToken") || "";
}

export function getUserId(): string {
  return localStorage.getItem("UserId") || "";
}

/* eslint-disable @typescript-eslint/restrict-template-expressions */
const isProduction = () => process.env.NODE_ENV === "production";

export const getDomain = () => {
  // AWS URL const prodUrl = "http://DEV-PROD-BALANCER-13763659248.eu-west-1.elb.amazonaws.com:8080";
  const prodUrl = `https://energy-mate.ifi.uzh.ch/backend/api`;
  const devUrl = `http://127.0.0.1:4000/api`;

  return isProduction() ? prodUrl : devUrl;
};

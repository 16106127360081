import React from "react";

const DataPrivacyNotice: React.FC = () => {
  const noticeStyle = {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#f9f9f9",
  };

  const headingStyle = {
    fontSize: "24px",
    marginBottom: "20px",
  };

  const sectionStyle = {
    marginBottom: "20px",
    fontSize: "16px",
    lineHeight: "1.5",
  };

  return (
    <div style={noticeStyle}>
      <h2 style={headingStyle}>Data Privacy Notice for Energy Mate</h2>

      <div style={sectionStyle}>
        <h3>Purpose</h3>
        <p>
          <strong>The Energy Mate Garmin watch app and Web-app</strong> is
          designed to assist users in tracking and managing their energy levels
          in relation to their daily activities. These apps will be used in a
          research study to gain insights into energy patterns throughout the
          day. Your participation in this study will contribute to our and your
          understanding of energy management.
        </p>
      </div>

      <div style={sectionStyle}>
        <h3>Data Collection</h3>
        <p>
          <strong>During the study,</strong> the Energy Mate watch app will
          collect data related to your mental and physical energy levels as well
          as current activities. Additionally, your heart rate and body battery
          will be used to have a measured value for the physical energy level.
          This values will be visualized to the user in the Energy Mate Web App.
          This data will be used exclusively for research purposes and will not
          be shared with any third parties.
        </p>
      </div>

      <div style={sectionStyle}>
        <h3>Data Storage and Confidentiality</h3>
        <p>
          <strong>All data collected</strong> by the Energy Mate watch and web
          app will be securely stored on your Garmin device and on our
          University of Zürich (UZH) Server (location in Switzerland). The data
          will not be transmitted to any other location or shared with other
          individuals or organizations. Your privacy and the confidentiality of
          your data are of utmost importance to us.
        </p>
      </div>

      <div style={sectionStyle}>
        <h3>Data Usage</h3>
        <p>
          <strong>The data collected during the study</strong> will be used
          solely for research and analysis related to energy levels and activity
          patterns. It will not be used for marketing or commercial purposes.
        </p>
      </div>

      <div style={sectionStyle}>
        <h3>Benefits and Risks</h3>
        <p>
          <strong>Participating in this study</strong> will provide you with
          insights into your energy levels and how they relate to your daily
          activities. The main risk associated with participation is the
          potential time commitment required to use the Energy Mate apps and
          provide feedback.
        </p>
      </div>

      <div style={sectionStyle}>
        <h3>Withdrawal</h3>
        <p>
          <strong>You have the right</strong> to withdraw from the study at any
          time without providing a reason. Your data will be deleted from the
          apps and servers upon withdrawal.
        </p>
      </div>

      <div style={sectionStyle}>
        <h3>Contact for Information about the Study</h3>
        <p>
          If you have any questions or need further information about the study,
          please contact Marinja Principe (
          <a href="mailto:marinja.principe@uzh.ch">marinja.principe@uzh.ch</a>)
          or Dr. André Meyer (
          <a href="mailto:ameyer@ifi.uzh.ch">ameyer@ifi.uzh.ch</a>). By using
          the Energy Mate apps and participating in this study, you consent to
          the collection and use of your data as described in this notice.
          <br />
          <br />
          Your contribution to this research is highly valued, and we appreciate
          your participation in helping us gain insights into energy management.
          <br />
          <br />
          Thank you for your cooperation.
        </p>
      </div>
    </div>
  );
};

export default DataPrivacyNotice;

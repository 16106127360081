import * as React from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { green } from "@mui/material/colors";

export const CustomButton = styled(Button)<ButtonProps>(
  ({ theme, variant }) => ({
    color: variant === "contained" ? "white" : "black",
    backgroundColor: variant === "contained" ? "#61B776" : "transparent",
    "&:hover": {
      backgroundColor: "#a2d3ad",
    },
  })
);

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SubmitHandler, useForm } from "react-hook-form";
import { api } from "../utils/api";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://hasel.dev/">
        Hasel-UZH
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

type LoginForm = {
  userId: string;
  userKey: string;
};

type LoginFormResponseData = {
  data: {
    jwt: string;
  };
};

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#61B776",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#a2d3ad",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#000000",
    },
  },
});

export default function Login(): JSX.Element {
  const {
    register,
    handleSubmit,
    setError,
    formState: { isValid },
  } = useForm<LoginForm>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<LoginForm> = (formData) => {
    api
      .post("users/login", formData)
      .then((response: LoginFormResponseData) => {
        localStorage.setItem("AuthToken", response.data.jwt);
        localStorage.setItem("UserId", formData.userId);
        window.location.reload();
      })
      .catch((error) => {
        setError("userId", { type: "focus" });
        setError("userKey", { type: "focus" });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-plus-operands
        alert(error + ": \n" + error.response.data.detail);
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#61B776" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              {...register("userId", { required: true })}
              margin="normal"
              required
              fullWidth
              label="User ID"
              autoFocus
            />
            <TextField
              {...register("userKey", { required: true })}
              margin="normal"
              required
              fullWidth
              label="User key"
              type="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isValid}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item>
                <Link href="mailto:marinja.principe@uzh.ch" variant="body2">
                  {
                    "Don't have an account and want to participate in the study? Contact Us "
                  }
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

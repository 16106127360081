import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getDailyEnergyData } from "../../slices/EnergyReportSlice";
import {
  determineIconAccordingToAverageEnergy,
  getDateFromString,
  getTimeFromString,
} from "../../utils/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ChartsAxisContentProps } from "@mui/x-charts/ChartsTooltip/ChartsAxisTooltipContent";
import {
  activityColor,
  measuredPhysicalEnergyColor,
  mentalEnergyColor,
  selfReportPhysicalEnergyColor,
} from "../../utils/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { WeeklyEnergyPlotProps } from "./WeeklyEnergyPlot";

export default function DailyEnergyPlot({ setAvatar }: WeeklyEnergyPlotProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [averageEnergy, setAverageEnergy] = React.useState<number | null>(null);
  const [energyData, setEnergyData] = React.useState({
    selfReportMentalEnergy: [
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selfReportPhysicalEnergy: [
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    measuredPhysicalEnergy: [
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    activities: [
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
    ],
  });
  const [xAxis, setxAxis] = React.useState([
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
  ]);
  const today = new Date().setHours(0, 0, 0, 0);
  const [selectedDay, setSelectedDay] = React.useState(today);
  const minWidth = 100;
  const minHeight = 50;
  const [chartWidth, setChartWidth] = React.useState(minWidth);
  const [chartHeight, setChartHeight] = React.useState(minHeight);

  const updateDateInUrl = (newDate: string) => {
    navigate(`?date=${newDate}`);
  };

  const fetchEnergyData = async () => {
    try {
      const response = await getDailyEnergyData(
        dayjs(selectedDay).format("YYYY-MM-DD")
      );
      setEnergyData({
        selfReportMentalEnergy: response.selfReportMentalEnergyValues,
        selfReportPhysicalEnergy: response.selfReportPhysicalEnergyValues,
        measuredPhysicalEnergy: response.measuredPhysicalEnergyValues,
        activities: response.activities,
      });
      setxAxis(response.timeValues);
      setAverageEnergy(response.averageEnergyValue);
      setAvatar(
        determineIconAccordingToAverageEnergy(response.averageEnergyValue)
      ); // set avatar number
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const changeToToday = () => {
    setSelectedDay(new Date().setHours(0, 0, 0, 0));
  };
  React.useEffect(() => {
    const handleResize = () => {
      // Calculate width and height based on window size with minimum constraints
      setChartWidth(Math.max(window.innerWidth * 0.95, minWidth));
      setChartHeight(Math.max(window.innerHeight * 0.5, minHeight));
    };
    // Set initial dimensions
    handleResize();
    // Add event listener
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Read date from URL on mount
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dateFromUrl = queryParams.get("date");
    if (dateFromUrl) {
      setSelectedDay(new Date(dateFromUrl).setHours(0, 0, 0, 0));
    }
  }, []);

  React.useEffect(() => {
    // Define a function to fetch energy data
    void fetchEnergyData();
    updateDateInUrl(dayjs(selectedDay).format("YYYY-MM-DD"));
  }, [selectedDay]);

  const CustomItemTooltipContent = (props: ChartsAxisContentProps) => {
    const activity =
      energyData.activities[props.dataIndex ? props.dataIndex : 0];
    const time = xAxis[props.dataIndex ? props.dataIndex : 0];

    return (
      <Paper sx={{ padding: 3 }}>
        <p>
          Reported at:{" "}
          {!getTimeFromString(time) ? "no report" : getTimeFromString(time)}
        </p>
        <p>
          Activity: <span style={{ color: activityColor }}>{activity}</span>
        </p>
        <Divider />
        <p>
          Self-report mental Energy:{" "}
          <span style={{ color: mentalEnergyColor }}>
            {
              energyData.selfReportMentalEnergy[
                props.dataIndex ? props.dataIndex : 0
              ]
            }
          </span>
        </p>
        <p>
          Self-report physical Energy:{" "}
          <span style={{ color: selfReportPhysicalEnergyColor }}>
            {
              energyData.selfReportPhysicalEnergy[
                props.dataIndex ? props.dataIndex : 0
              ]
            }
          </span>
        </p>
        <p>
          Measured physical Energy:{" "}
          <span style={{ color: measuredPhysicalEnergyColor }}>
            {
              energyData.measuredPhysicalEnergy[
                props.dataIndex ? props.dataIndex : 0
              ]
            }
          </span>
        </p>
      </Paper>
    );
  };

  return (
    <>
      {!!energyData &&
      !!energyData.selfReportMentalEnergy &&
      !!energyData.selfReportPhysicalEnergy &&
      !!energyData.activities &&
      energyData.selfReportMentalEnergy.length >= 1 &&
      energyData.selfReportPhysicalEnergy.length >= 1 &&
      energyData.activities.length >= 1 &&
      energyData.activities.length ===
        energyData.selfReportMentalEnergy.length &&
      energyData.selfReportMentalEnergy.length ===
        energyData.selfReportPhysicalEnergy.length ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="50%"
        >
          <Grid
            item
            style={{
              width: chartWidth,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#61B776",
                    "&:hover": {
                      bgcolor: "#a2d3ad", // Replace 'shadeOfGreen' with the desired shade
                    },
                  }}
                  onClick={changeToToday}
                >
                  Today
                </Button>
                <DatePicker
                  label="Selected date"
                  maxDate={dayjs(today)}
                  value={dayjs(selectedDay)}
                  onChange={(newValue) => {
                    newValue
                      ? setSelectedDay(newValue.valueOf())
                      : setSelectedDay(selectedDay);
                  }}
                />
                <TextField
                  label="Today's average energy in %"
                  variant="outlined"
                  value={!!averageEnergy ? averageEnergy : "-"}
                  disabled={true}
                  InputProps={{
                    readOnly: true, // Make the text field read-only
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            style={{
              width: chartWidth,
              height: chartHeight,
            }}
          >
            <LineChart
              tooltip={{
                trigger: "axis",
                axisContent: CustomItemTooltipContent,
              }}
              xAxis={[
                {
                  label: "Report time",
                  scaleType: "point",
                  data: xAxis,
                  valueFormatter: getDateFromString,
                },
              ]}
              yAxis={[{ label: "Energy in %" }]}
              series={[
                {
                  curve: "monotoneX",
                  data: energyData.selfReportMentalEnergy,
                  connectNulls: true,
                  color: mentalEnergyColor,
                  label: "Self-report mental energy",
                },
                {
                  curve: "monotoneX",
                  data: energyData.selfReportPhysicalEnergy,
                  connectNulls: true,
                  color: selfReportPhysicalEnergyColor,
                  label: "Self-report physical energy",
                },
                {
                  curve: "monotoneX",
                  data: energyData.measuredPhysicalEnergy,
                  connectNulls: true,
                  color: measuredPhysicalEnergyColor,
                  label: "Measured physical energy",
                },
              ]}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography>
          {" "}
          Ups.... Apparently, our Energy is sometimes low, too. Please try again
          by reloading.😖
        </Typography>
      )}
    </>
  );
}

import * as React from "react";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Button, ButtonGroup, Grid, Stack, TextField } from "@mui/material";
import { getActivityData, getEnergyData } from "../../slices/EnergyReportSlice";
import {
  dateToString,
  determineIconAccordingToAverageEnergy,
  getMonday,
  getSonday,
} from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import EnergyPlot from "./EnergyPlot";
import { ActivityHeatmap } from "./ActivityHeatmap";
import { CustomButton } from "../CustomButton";

export interface EnergyData {
  selfReportMentalEnergy: number[];
  selfReportPhysicalEnergy: number[];
  measuredPhysicalEnergy: number[];
}

export interface ActivityData {
  timeValues: string[];
  activities: string[];
  activitiesCount: number[][];
  averageEnergyImprovement: number[][];
}

export interface WeeklyEnergyPlotProps {
  setAvatar: (avatar: number) => void;
}

export default function WeeklyEnergyPlot({ setAvatar }: WeeklyEnergyPlotProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [averageEnergy, setAverageEnergy] = React.useState<number | null>(null);
  const [energyData, setEnergyData] = React.useState<EnergyData>({
    selfReportMentalEnergy: [0, 0, 0, 0, 0, 0, 0],
    selfReportPhysicalEnergy: [0, 0, 0, 0, 0, 0, 0],
    measuredPhysicalEnergy: [0, 0, 0, 0, 0, 0, 0],
  });
  const [activityData, setActivityData] = React.useState<ActivityData>({
    timeValues: [],
    activities: [],
    activitiesCount: [],
    averageEnergyImprovement: [],
  });
  const [xAxis, setxAxis] = React.useState([
    "Mon",
    "Tue",
    "Wed",
    "Thr",
    "Fr",
    "Sat",
    "Son",
  ]);
  const today = new Date(); // Current date and time
  const [error, setError] = React.useState<DateValidationError | null>(null);

  // Set the start time to the beginning of the day (midnight)
  const [startDate, setStartDate] = React.useState(
    getMonday(dateToString(today)).setHours(0, 0, 0, 0)
  );
  // Set the end time to the end of the day (23:59:59:999)
  const [endDate, setEndDate] = React.useState(
    getSonday(dateToString(today)).setHours(23, 59, 59, 999)
  );
  const minWidth = 400;
  const minHeight = 300;
  const [chartWidth, setChartWidth] = React.useState(minWidth);
  const [chartHeight, setChartHeight] = React.useState(minHeight);
  const [selectedActivityView, setSelectedActivityView] = React.useState("all"); // activity filter selection

  const handleValueChange = (value: string) => {
    setSelectedActivityView(value);
  };

  const updateDateInUrl = (startDate: string, endDate: string) => {
    navigate(`?startDate=${startDate}&endDate=${endDate}`);
  };

  const fetchEnergyData = async (startTime: number, endTime: number) => {
    try {
      const response = await getEnergyData(
        dayjs(startTime).format("YYYY-MM-DD"),
        dayjs(endTime).format("YYYY-MM-DD")
      );
      setEnergyData({
        selfReportMentalEnergy: response.selfReportMentalEnergyValues,
        selfReportPhysicalEnergy: response.selfReportPhysicalEnergyValues,
        measuredPhysicalEnergy: response.measuredPhysicalEnergyValues,
      });
      setxAxis(response.timeValues);
      setAverageEnergy(response.averageEnergyValue);
      setAvatar(
        determineIconAccordingToAverageEnergy(response.averageEnergyValue)
      ); // set avatar number
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchActivityData = async (
    startTime: number,
    endTime: number,
    selectedActivityView: string
  ) => {
    try {
      const response = await getActivityData(
        dayjs(startTime).format("YYYY-MM-DD"),
        dayjs(endTime).format("YYYY-MM-DD"),
        selectedActivityView
      );
      setActivityData({
        timeValues: response.timeValues,
        activities: response.activities,
        activitiesCount: response.activitiesCount,
        averageEnergyImprovement: response.averageEnergyImprovement,
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const changeToThisWeek = () => {
    setStartDate(getMonday(dateToString(today)).setHours(0, 0, 0, 0));
    setEndDate(getSonday(dateToString(today)).setHours(23, 59, 59, 999));
  };

  React.useEffect(() => {
    const handleResize = () => {
      // Calculate width and height based on window size with minimum constraints
      setChartWidth(Math.max(window.innerWidth * 0.8, minWidth));
      setChartHeight(Math.max(window.innerHeight * 0.5, minHeight));
    };

    // Set initial dimensions
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Read date from URL on mount
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const startDateFromUrl = queryParams.get("startDate");
    const endDateFromUrl = queryParams.get("endDate");
    if (startDateFromUrl) {
      setStartDate(new Date(startDateFromUrl).setHours(0, 0, 0, 0));
    } else if (endDateFromUrl) {
      setEndDate(new Date(endDateFromUrl).setHours(23, 59, 59, 999));
    }
  }, []);

  React.useEffect(() => {
    // Define a function to fetch energy data
    if (startDate < endDate) {
      updateDateInUrl(
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD")
      );
      void fetchEnergyData(startDate, endDate); // Call the function when startTime or endTime change
      void fetchActivityData(startDate, endDate, selectedActivityView);
    }
  }, [startDate, endDate, selectedActivityView]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      marginTop={2}
    >
      <Grid
        item
        style={{
          width: chartWidth,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              sx={{
                bgcolor: "#61B776",
                "&:hover": {
                  bgcolor: "#a2d3ad",
                },
              }}
              onClick={changeToThisWeek}
            >
              This week
            </Button>
            <DatePicker
              label="Start date"
              value={dayjs(startDate)}
              onChange={(newStartValue) => {
                newStartValue
                  ? setStartDate(newStartValue.valueOf())
                  : setStartDate(startDate);
              }}
            />
            <DatePicker
              label="End date"
              minDate={dayjs(startDate).add(1, "day")}
              value={dayjs(endDate)}
              onChange={(newEndValue) => {
                newEndValue
                  ? setEndDate(newEndValue.valueOf())
                  : setEndDate(endDate);
              }}
              onError={(newError) => setError(newError)}
              slotProps={{
                textField: {
                  helperText: error
                    ? "End date has to be later then start date"
                    : "",
                },
              }}
            />
            <TextField
              label="Average energy in %"
              variant="outlined"
              value={!!averageEnergy ? averageEnergy : "-"}
              disabled={true}
              InputProps={{
                readOnly: true, // Make the text field read-only
              }}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>
      <Grid justifyContent="center" marginBlockEnd={5}>
        <Grid item sx={{ verticalAlign: "middle" }}>
          <EnergyPlot
            xAxis={xAxis}
            energyData={energyData}
            chartHeight={chartHeight}
            chartWidth={chartWidth}
          />
        </Grid>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <CustomButton
            onClick={() => handleValueChange("all")}
            variant={selectedActivityView === "all" ? "contained" : "text"}
          >
            All Values
          </CustomButton>
          <CustomButton
            onClick={() => handleValueChange("mental")}
            variant={selectedActivityView === "mental" ? "contained" : "text"}
          >
            Mental Energy
          </CustomButton>
          <CustomButton
            onClick={() => handleValueChange("physical")}
            variant={selectedActivityView === "physical" ? "contained" : "text"}
          >
            Physical Energy
          </CustomButton>
          <CustomButton
            onClick={() => handleValueChange("bodyBattery")}
            variant={
              selectedActivityView === "bodyBattery" ? "contained" : "text"
            }
          >
            Body Battery Value
          </CustomButton>
        </ButtonGroup>
        <ActivityHeatmap
          activitiesCount={activityData.activitiesCount}
          timeValues={activityData.timeValues}
          activities={activityData.activities}
          averageEnergyImprovement={activityData.averageEnergyImprovement}
        />
      </Grid>
    </Grid>
  );
}

import React from "react";
import ResponsiveAppBar from "../Shared/ResponsiveAppBar";

export function AppLayout(props: {
  children: React.ReactElement;
  avatar: number;
}) {
  const { children } = props;
  return (
    <>
      <ResponsiveAppBar avatar={props.avatar} />
      {children}
    </>
  );
}

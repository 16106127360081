import React from "react";
import { getUser } from "../slices/UserSlice";
import { UserModel } from "../models/UserModel";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import DailyEnergyPlot from "./plots/DailyEnergyPlot";
import { WeeklyEnergyPlotProps } from "./plots/WeeklyEnergyPlot";

export default function Home({
  setAvatar,
}: WeeklyEnergyPlotProps): JSX.Element {
  const [user, setUser] = useState<UserModel>();

  // Get all communities with a specific search string
  async function getUserMe(search?: string) {
    try {
      const user: UserModel = await getUser();
      console.log("Received user data:", user);
      setUser(user);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  // Will run when the page is loaded
  useEffect(() => {
    void getUserMe();
  }, []);

  return (
    <>
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <Typography variant="h4">Your energy insights</Typography>
        <p>
          {" "}
          Hi! Buckle up for an energy rollercoaster!
          <br /> Here is your ticket to a wild ride through your daily power
          adventures. Unleash your inner energy explorer with the date pickers
          and discover shocking revelations!
        </p>
      </Box>
      <DailyEnergyPlot setAvatar={setAvatar} />
    </>
  );
}

import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"; // Make sure to update the import

import Home from "../Home";
import Login from "../../pages/Login";
import Help from "../Help";
import { AppLayout } from "./AppLayout";
import DataPrivacyNotice from "../DataPrivacyNotice";
import { isLoggedIn } from "../../utils/tokenUtils";
import WeeklyEnergyInsights from "../WeeklyEnergyInsights";

const AppRouter = () => {
  const [avatar, setAvatar] = React.useState<number>(0);

  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn() ? (
          /* User is logged in */
          <>
            <Route
              path="/"
              element={
                <AppLayout avatar={avatar}>
                  <Home setAvatar={setAvatar} />
                </AppLayout>
              }
            />
            <Route
              path="/home"
              element={
                <AppLayout avatar={avatar}>
                  <Home setAvatar={setAvatar} />
                </AppLayout>
              }
            />
            <Route
              path="/energy-plot"
              element={
                <AppLayout avatar={avatar}>
                  <WeeklyEnergyInsights setAvatar={setAvatar} />
                </AppLayout>
              }
            />
            <Route path="/login" element={<Navigate to="/" replace />} />
          </>
        ) : (
          /* User is NOT logged in */
          <>
            <Route
              path="/login"
              element={
                <AppLayout avatar={avatar}>
                  <Login />
                </AppLayout>
              }
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        )}
        <Route
          path="/help"
          element={
            <AppLayout avatar={avatar}>
              <Help />
            </AppLayout>
          }
        />
        <Route
          path="/data-privacy"
          element={
            <AppLayout avatar={avatar}>
              <DataPrivacyNotice />
            </AppLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

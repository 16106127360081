// Legend Component
export const HeatmapLegend = () => {
  const gradient = `linear-gradient(to right, 
    hsl(0, 100%, 50%) 0%, 
    hsl(0, 100%, 50%) 13%, 
    hsl(0, 72%, 71%) 26%, 
    hsl(0, 58%, 85%) 39%, 
    hsl(133, 36%, 73%) 52%, 
    hsl(133, 32%, 56%) 65%, 
    hsl(133, 36%, 41%) 78%, 
    hsl(133, 80%, 20%) 91%)`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "300px",
        position: "relative",
      }}
    >
      <div
        style={{ height: 20, width: "100%", backgroundImage: gradient }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 5,
        }}
      >
        <span style={{ position: "absolute", left: "0%" }}>{"<-50%"}</span>
        <span style={{ position: "absolute", left: "24%" }}>{"-20%"}</span>
        <span style={{ position: "absolute", left: "48%" }}>{"0%"}</span>
        <span style={{ position: "absolute", left: "72%" }}>{"20%"}</span>
        <span style={{ position: "absolute", left: "94%" }}>{">50%"}</span>
      </div>
    </div>
  );
};

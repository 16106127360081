import React from "react";
import { Box, Typography } from "@mui/material";
import WeeklyEnergyPlot, {
  WeeklyEnergyPlotProps,
} from "./plots/WeeklyEnergyPlot";

export default function WeeklyEnergyInsights({
  setAvatar,
}: WeeklyEnergyPlotProps): JSX.Element {
  return (
    <>
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <Typography variant="h4">Your weekly energy insights</Typography>
      </Box>
      <WeeklyEnergyPlot setAvatar={setAvatar} />
    </>
  );
}

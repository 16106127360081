import { ActivityReportModel } from "../models/ActivityReportModel";
import { DailyEnergyReportModel } from "../models/DailyEnergyReportModel";
import { EnergyReportModel } from "../models/EnergyReportModel";
import { api } from "../utils/api";
import { getUserId } from "../utils/tokenUtils";

export async function getEnergyData(
  startTime: string,
  endTime: string
): Promise<EnergyReportModel> {
  try {
    const response = await api.get<EnergyReportModel>(
      "/selfreport/" + getUserId(),
      {
        params: {
          // Use formatted date strings as query parameters
          startDate: startTime,
          endDate: endTime,
        },
      }
    );
    const energyData: EnergyReportModel = response.data;
    return energyData;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

export async function getActivityData(
  startTime: string,
  endTime: string,
  selectedActivityView: string
): Promise<ActivityReportModel> {
  try {
    const response = await api.get<ActivityReportModel>(
      "/selfreport/activities/" + getUserId(),
      {
        params: {
          // Use formatted date strings as query parameters
          startDate: startTime,
          endDate: endTime,
          selectedActivityView: selectedActivityView,
        },
      }
    );
    const activityData: ActivityReportModel = response.data;
    return activityData;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

export async function getDailyEnergyData(
  selectedDay: string
): Promise<DailyEnergyReportModel> {
  try {
    const response = await api.get<DailyEnergyReportModel>(
      "/selfReport/" + selectedDay + "/" + getUserId()
    );
    const energyData: DailyEnergyReportModel = response.data;
    return energyData;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

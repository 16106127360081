import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { ThemeProvider, createTheme } from "@mui/material";
import energyMate from "../../images/energyMate.png";
import energyMateHappy from "../../images/energyMateHappy.png";
import energyMateSad from "../../images/energyMateSad.png";
import energyMateTired from "../../images/EnergyMateTired.png";
import { isLoggedIn } from "../../utils/tokenUtils";
import { useLocation, useNavigate } from "react-router-dom";

const pages = getPages();

function getPages() {
  const pages = [
    { label: "Help", redirection: "help" },
    { label: "Data privacy notice", redirection: "data-privacy" },
  ];
  if (isLoggedIn()) {
    pages.unshift({ label: "Weekly energy", redirection: "energy-plot" });
    pages.unshift({ label: "Energy insights", redirection: "home" });
  }
  return pages;
}

export interface avatarProps {
  avatar: number;
}

function ResponsiveAppBar({ avatar }: avatarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page: string) => {
    if (!pages[0]) return;
    window.location.href = page;
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#61B776",
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: "#a2d3ad",
        light: "#F5EBFF",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#000000",
      },
    },
  });

  function getIconImage(): string {
    if (avatar === 1) {
      return energyMateSad;
    } else if (avatar === 2) {
      return energyMateTired;
    } else if (avatar === 3) {
      return energyMateHappy;
    } else {
      return energyMate;
    }
  }

  function logout() {
    localStorage.removeItem("UserId");
    localStorage.removeItem("AuthToken");
    localStorage.removeItem("AverageEnergyType");
    window.location.reload();
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="secondary">
        <Container maxWidth={false}>
          <Toolbar>
            {isLoggedIn() ? (
              <img
                src={getIconImage()}
                alt="energy mate icon"
                width="50px"
                onClick={() => setMenuOpen(!menuOpen)}
              />
            ) : (
              <img src={energyMate} alt="energy mate icon" width="50px" />
            )}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Energy Mate
            </Typography>
            <Menu
              sx={{ display: { xs: "flex", md: "none" } }}
              id="menu-appbar"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={menuOpen}
              onClose={() => setMenuOpen(!menuOpen)}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.label}
                  sx={{
                    color: "black",
                    display: "block",
                    "&:hover": {
                      bgcolor: "#61B776", // Green background on hover
                    },
                    bgcolor:
                      currentPath === "/" + page.redirection
                        ? "#61B776"
                        : "inherit", // Green background if current page
                  }}
                  onClick={() => handleCloseNavMenu(page.redirection)}
                >
                  {page.label}
                </MenuItem>
              ))}
            </Menu>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <MenuItem
                  key={page.label}
                  sx={{
                    color: "white",
                    display: "block",
                    "&:hover": {
                      bgcolor: "#61B776", // Green background on hover
                    },
                    bgcolor:
                      currentPath === "/" + page.redirection
                        ? "#61B776"
                        : "inherit", // Green background if current page
                  }}
                  onClick={() => handleCloseNavMenu(page.redirection)}
                >
                  {page.label}
                </MenuItem>
              ))}
            </Box>

            <Box sx={{ float: "right", marginLeft: "auto" }}>
              {isLoggedIn() ? (
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{ bgcolor: "#61B776" }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Avatar
                  sx={{ m: 1, bgcolor: "#61B776" }}
                  onClick={() => navigate("/login")}
                >
                  <LockOutlinedIcon />
                </Avatar>
              )}
              <Menu
                sx={{ mt: "45px" }}
                id="menu-settings"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {isLoggedIn() ? (
                  <>
                    <MenuItem key={"Logout"} onClick={() => logout()}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </>
                ) : (
                  <></>
                )}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
export default ResponsiveAppBar;

import React from "react";
import { Typography, Grid, Button, Divider } from "@mui/material";
import dailyView from "../images/dailyView.png";
import weeklyView from "../images/weeklyView.png";
import watchApp from "../images/WatchApp.png";

export default function Help(): JSX.Element {
  return (
    <Grid container spacing={3} padding={3}>
      {/* Introduction to Energy Mate */}
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          What is Energy Mate?
        </Typography>
        <Typography>
          Energy Mate is a comprehensive tracking system consisting of a Watch
          app and a web app. It monitors your energy levels through measured
          body battery and self-report data. Visualizations are provided to
          offer insights into energy patterns, helping users understand
          activities that contribute to recharging or depleting their energy.
        </Typography>
      </Grid>

      {/* Access Information */}
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          How to Access Energy Mate?
        </Typography>
        <Typography>
          Participation in the Energy Mate study is exclusive. If you wish to
          join, please{" "}
          <a
            href="mailto:marinja.principe@uzh.ch"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </a>
          . If you are participant, please follow the instruction, which can be
          found{" "}
          <a
            href="https://drive.google.com/file/d/1AJeqrNNmDx_GiPGwFchjZiDoLK10Qn0t/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </Typography>
      </Grid>

      {/* User Tour */}
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          A short Tour of Energy Mate
        </Typography>
        <Typography variant="h6" gutterBottom>
          Watch App
        </Typography>
        <Typography>
          The Energy Mate Watch App will send you each hour a reminder to fill
          out the self-report on your current mental and physical energy level.
          <br />
          If the default start and endtime or reporting days is not to your
          liking you can adjust them on your phone Connect App under Energy Mate
          Settings.
          <br />
          <a
            href="https://support.garmin.com/en-US/?faq=SPo0TFvhQO04O36Y5TYRh5"
            target="_blank"
            rel="noopener noreferrer"
          >
            Here
          </a>{" "}
          you can find more information on how to change the settings.
          <br />
          <img src={watchApp} style={{ maxWidth: "20%" }} />
          <br />
          <Typography variant="h6" gutterBottom>
            Web App
          </Typography>
          <strong>Daily Insights</strong> Visualize the fluctuation of your
          mental and physical self-reported energy levels along with your
          measured body battery. Hover over data points to see more detailed
          information, and select a specific day to view historical data.
          <br />
          <img
            src={dailyView}
            style={{
              maxWidth: "70%",
            }}
          />
          <br />
          <strong>Weekly Insights</strong> Explore average mental and physical
          self-reported values and body battery per day for the selected time
          span. Additionally, find an activity heat map showcasing the average
          improvement for each activity per day.
          <br />
          <img src={weeklyView} style={{ maxWidth: "70%" }} />
          <br />
          <strong>Data Privacy Notice</strong>
          In the privacy notice, find essential information regarding data
          privacy.
        </Typography>
      </Grid>

      {/* Help Information */}
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Need Help?
        </Typography>
        <Typography>
          If you encounter any issues:
          <br />
          - First, reload the browser.
          <br />
          - Logout and login again.
          <br />- For further assistance,{" "}
          <a
            href="mailto:marinja.principe@uzh.ch"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </a>
          .
        </Typography>
      </Grid>
    </Grid>
  );
}

import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import { ActivityData } from "./WeeklyEnergyPlot";
import { HeatmapLegend } from "./HeatmapLegend";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const ActivityHeatmap = ({
  timeValues,
  activities,
  activitiesCount,
  averageEnergyImprovement,
}: ActivityData) => {
  const [showDescription, setShowDescription] = useState(false);
  var max = 4;

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const getColor = (value: number) => {
    // Keeping the hue fixed at 120 (green)
    if (value <= -50) {
      return `hsl(0, 100%, 50%)`;
    } else if (value <= -20) {
      return `hsl(0, 100%, 50%)`;
    } else if (value <= -10) {
      return `hsl(0, 72%, 71%)`;
    } else if (value < 0) {
      return `hsl(0, 58%, 85%)`;
    } else if (value <= 10) {
      return `hsl(133, 36%, 73%)`;
    } else if (value <= 20) {
      return `hsl(133, 32%, 56%)`;
    } else if (value <= 50) {
      return `hsl(133, 36%, 41%)`;
    } else {
      return `hsl(133, 80%, 20%)`;
    }
  };

  const handleClose = () => {
    setShowDescription(!showDescription);
  };

  if (
    !timeValues ||
    !activities ||
    !activitiesCount ||
    !averageEnergyImprovement ||
    timeValues.length < 1 ||
    activities.length < 1 ||
    activitiesCount.length < 1 ||
    averageEnergyImprovement.length < 1 ||
    averageEnergyImprovement[0].length !== timeValues.length ||
    averageEnergyImprovement.length !== activities.length ||
    activitiesCount.length !== activities.length ||
    activitiesCount[0].length !== timeValues.length
  ) {
    return (
      <Paper elevation={3} sx={{ margin: 3, padding: 2 }}>
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          Unfortunatelly, we couldn't spot any activities in this time frame.
          🕵️‍♂️🔍 <br />
          Try adjusting your time machine (I mean, time filter) to catch them in
          action!
        </Typography>
      </Paper>
    );
  }

  return (
    <>
      <IconButton onClick={toggleDescription}>
        <HelpOutlineIcon />
      </IconButton>
      <Dialog
        open={showDescription}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Activity heatmap"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <big color="Green">Goal: </big>The activity heatmap reveals the
            average improvement of the activity per day.📊 An improvement is
            measured by the mental and physical improvement between two reports.
            This improvement is like a personal victory – a little mental and
            physical win!🏆
            <br />
            <big>How to use it: </big>Using the <big>Selection Buttons </big>{" "}
            above the activity heat map will enable you to analyze your activity
            improvement by mental, physical or measured energy.
            <br />
            Dive into the chart and enjoy the journey of improvement! 🚀 😄
            <br />
            <br />
            Please note: This heatmap does not consider the first activity of
            the day.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <table style={{ width: "100%" }}>
        <thead>
          <tr style={{ verticalAlign: "middle" }}>
            <th>Activities</th> {/* Empty cell for the top-left corner */}
            {timeValues.map((label, index) => (
              <th key={index}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {averageEnergyImprovement.map((row, i) => (
            <tr key={i}>
              <td
                style={{
                  verticalAlign: "middle",
                }}
              >
                {activities[i]}
              </td>
              {/* Y-axis label */}
              {row.map((cell, j) => (
                <Tooltip
                  title={
                    <div
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >{`Average energy improvement: ${cell}% \n Activity count: ${activitiesCount[i][j]}`}</div>
                  }
                >
                  <td
                    key={j}
                    style={{
                      backgroundColor: getColor(cell),
                      color: cell > max / 2 ? "white" : "black",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {cell}
                  </td>
                </Tooltip>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <HeatmapLegend />
    </>
  );
};

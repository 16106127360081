import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { Divider, Paper, Typography } from "@mui/material";
import { ChartsAxisContentProps } from "@mui/x-charts/ChartsTooltip/ChartsAxisTooltipContent";
import {
  measuredPhysicalEnergyColor,
  mentalEnergyColor,
  selfReportPhysicalEnergyColor,
} from "../../utils/theme";
import { EnergyData } from "./WeeklyEnergyPlot";

interface EnergyPlotProps {
  energyData: EnergyData;
  xAxis: string[];
  chartWidth: number;
  chartHeight: number;
}

export default function EnergyPlot({
  energyData,
  xAxis,
  chartWidth,
  chartHeight,
}: EnergyPlotProps) {
  const CustomItemTooltipContent = (props: ChartsAxisContentProps) => {
    const date = xAxis[props.dataIndex ? props.dataIndex : 0];

    return (
      <Paper sx={{ padding: 3 }}>
        <p>Reported at: {date}</p>
        <Divider />
        <p>
          Self-report mental Energy:{" "}
          <span style={{ color: mentalEnergyColor }}>
            {
              energyData.selfReportMentalEnergy[
                props.dataIndex ? props.dataIndex : 0
              ]
            }
          </span>
        </p>
        <p>
          Self-report physical Energy:{" "}
          <span style={{ color: selfReportPhysicalEnergyColor }}>
            {
              energyData.selfReportPhysicalEnergy[
                props.dataIndex ? props.dataIndex : 0
              ]
            }
          </span>
        </p>
        <p>
          Measured physical Energy:{" "}
          <span style={{ color: measuredPhysicalEnergyColor }}>
            {
              energyData.measuredPhysicalEnergy[
                props.dataIndex ? props.dataIndex : 0
              ]
            }
          </span>
        </p>
      </Paper>
    );
  };

  if (
    !energyData ||
    !energyData.selfReportMentalEnergy ||
    !energyData.selfReportPhysicalEnergy ||
    !energyData.measuredPhysicalEnergy ||
    energyData.selfReportMentalEnergy.length < 1 ||
    energyData.selfReportPhysicalEnergy.length < 1 ||
    energyData.measuredPhysicalEnergy.length < 1 ||
    energyData.selfReportMentalEnergy.length !==
      energyData.selfReportPhysicalEnergy.length
  ) {
    return (
      <Paper elevation={3} sx={{ margin: 3, padding: 2 }}>
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          Ups 😵‍💫... Apperantly, our energy is sometimes low, too. Please refresh
          the page. 🔄
        </Typography>
      </Paper>
    );
  }

  return (
    <LineChart
      tooltip={{
        trigger: "axis",
        axisContent: CustomItemTooltipContent,
      }}
      xAxis={[
        {
          scaleType: "point",
          data: xAxis,
        },
      ]}
      series={[
        {
          curve: "monotoneX",
          data: energyData.selfReportMentalEnergy,
          color: "#61B776",
          label: "Self-report mental energy",
        },
        {
          curve: "monotoneX",
          data: energyData.selfReportPhysicalEnergy,
          color: "#F0A400",
          label: "Self-report physical energy",
        },
        {
          curve: "monotoneX",
          data: energyData.measuredPhysicalEnergy,
          color: "#ef6902",
          label: "Measured physical energy",
        },
      ]}
      width={chartWidth}
      height={chartHeight}
    />
  );
}

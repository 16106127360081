export function determineIconAccordingToAverageEnergy(
  value: number | null
): number {
  if (value === null) {
    return 0;
  } else if (value < 30) {
    return 1;
  } else if (value < 50) {
    return 2;
  } else if (value >= 80) {
    return 3;
  } else {
    return 0;
  }
}

export function getMonday(today: string) {
  var d = new Date(today);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function getSonday(today: string) {
  var d = getMonday(today);
  d.setDate(d.getDate() + 6);
  return new Date(d);
}

export function dateToString(date: Date) {
  return date.toISOString().split("T")[0];
}

export function getTimeFromString(dateString: string): string {
  return dateString.split(" ")[1];
}

export function getDateFromString(dateString: string): string {
  return dateString.split(" ")[0];
}

import { UserModel } from "../models/UserModel";
import { api } from "../utils/api";

export async function getUser(): Promise<UserModel> {
  try {
    const response = await api.get<UserModel>("/users");
    const user: UserModel = response.data;
    return user;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error; // Rethrow the error to be handled by the caller
  }
}
